import React from "react"
import styled from 'styled-components';
import Layout from "../../components/layout/layout"
import { Helmet } from "react-helmet"

const VideoContainer = styled.div`
    min-height: calc(100vh - 139px);
    max-width: 820px;
    margin: auto;
    padding: 2.5em 0;
    width: 80%;

    @media only screen and (min-width: 900px) {
        min-height: calc(100vh - 179px)
    }
`;

const IFrame = styled.iframe`
    width: 100%;
    border: 0;
    overflow: hidden;
    height: 0;
    padding-top: 56.25%;
    @supports (aspect-ratio: 16 / 9) {
        aspect-ratio: 16 / 9;
        height: auto;
        padding: 0;
    }
`;

const CaseStudy = () => (
    <Layout>
        <Helmet>
            <meta charSet="UTF-8" />
            <title>Case Study | Caplin Solar</title>
            <html lang="en"></html>
        </Helmet>
        <VideoContainer>
            <IFrame 
                title="CaseStudy: Clopton" 
                src="https://player.vimeo.com/video/631413631?h=44af8aae71" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowFullScreen>
            </IFrame>
        </VideoContainer>
    </Layout>
)

export default CaseStudy;




